<template>
  <Dialog :commonDialog="personDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <span v-if="doUpdate">Update</span>
      <span v-else>Create</span>
      <span v-if="isTenant">&nbsp;Tenant</span
      ><span v-else>&nbsp;Property Address </span>
    </template>
    <template v-slot:body>
      <v-form
        ref="personForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="appendLocalPerson"
      >
        <v-row class="mx-3">
          <v-col class="pt-0" md="12" sm="12">
            <v-row>
              <v-col md="7" sm="7">
                <v-layout>
                  <v-flex class="mr-2">
                    <v-switch
                      v-model="trackGPS"
                      class="mt-0"
                      inset
                      label="Get your current location"
                      v-on:change="getUserCurrentAddress"
                      color="cyan"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col md="5" sm="5">
                <v-hover v-slot="{ hover }">
                  <div class="custom-google-autocomplete">
                    <v-text-field
                      id="google-address-map"
                      v-model.trim="searchAddress"
                      append-icon="search"
                      dense
                      filled
                      label="Search Address"
                      solo
                      flat
                      color="cyan"
                      :loading="autocompleteLoading"
                      v-on:keyup="getGoogleAddress"
                      v-on:blur="isFocused = false"
                      v-on:focus="isFocused = true"
                    >
                    </v-text-field>
                    <v-list
                      v-if="(hover || isFocused) && autocompleteList.length"
                      two-line
                      elevation="4"
                      class="custom-google-autocomplete-list"
                    >
                      <v-list-item
                        v-for="(address, index) in autocompleteList"
                        :key="index"
                        link
                        v-on:click="selectGoogleAddress(index)"
                      >
                        <v-list-item-icon class="m-0 mr-3 my-auto">
                          <v-icon color="cyan"> mdi-map-marker </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content
                          class="py-1"
                          v-if="
                            lodash.isEmpty(address.structured_formatting) ===
                            false
                          "
                        >
                          <v-list-item-title class="font-size-14">{{
                            address.structured_formatting.main_text
                          }}</v-list-item-title>
                          <v-list-item-subtitle class="font-size-12">{{
                            address.structured_formatting.secondary_text
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content class="py-1" v-else>
                          <v-list-item-title class="font-size-14">{{
                            address.description
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="4">
            <label class="font-size-16 font-weight-500"
              >Contact Person
              <!-- <pre>{{cc_persons}}</pre> --></label
            >
            <template>
              <v-select
                id="contact-persons"
                v-model.trim="propertyAddress.contact_person"
                :items="cc_persons"
                :rules="[
                  validateRules.required(
                    propertyAddress.contact_person,
                    'Contact Person'
                  ),
                ]"
                dense
                filled
                item-text="display_name"
                item-value="display_name"
                item-color="cyan"
                label="Contact Persons"
                solo
                flat
                color="cyan"
              ></v-select>
            </template>
            <!-- <template v-else>
                          <v-select
                            id="contact-persons"
                            v-model.trim="propertyAddress.contact_person"
                            :items="contactPersons"
                            :rules="[
                              validateRules.required(
                                propertyAddress.contact_person,
                                'Contact Person'
                              ),
                            ]"
                            dense
                            filled
                            item-text="display_name"
                            item-value="display_name"
                            item-color="cyan"
                            label="Contact Persons"
                            solo
                            flat
                            color="cyan"
                          ></v-select>
                        </template> -->
          </v-col>
          <v-col md="4" sm="12">
            <label class="font-size-16 font-weight-500">Unit No.</label>
            <v-text-field
              v-model.trim="propertyAddress.unit_no"
              :rules="[
                validateRules.maxLength(
                  propertyAddress.unit_no,
                  'Unit No.',
                  100
                ),
              ]"
              dense
              filled
              label="Unit No."
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="12">
            <label class="font-size-16 font-weight-500 required"
              >Address Line 1</label
            >
            <v-text-field
              v-model.trim="propertyAddress.street_1"
              :rules="[
                validateRules.required(
                  propertyAddress.street_1,
                  'Address Line 1'
                ),
                validateRules.maxLength(
                  propertyAddress.street_1,
                  'Address Line 1',
                  255
                ),
              ]"
              dense
              filled
              label="Address Line 1"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="12">
            <label class="font-size-16 font-weight-500">Address Line 2</label>
            <v-text-field
              v-model.trim="propertyAddress.street_2"
              :rules="[
                validateRules.maxLength(
                  propertyAddress.street_2,
                  'Address Line 2',
                  255
                ),
              ]"
              dense
              filled
              label="Address Line 2"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="12">
            <label class="font-size-16 font-weight-500 required"
              >Postal Code</label
            >
            <v-text-field
              v-model.trim="propertyAddress.zip_code"
              :rules="[
                validateRules.required(propertyAddress.zip_code, 'Postal Code'),
                validateRules.maxLength(
                  propertyAddress.zip_code,
                  'Postal Code',
                  10
                ),
              ]"
              dense
              filled
              v-mask="'######'"
              label="Postal Code"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="12">
            <label class="font-size-16 font-weight-500 required">Country</label>
            <v-text-field
              v-model.trim="propertyAddress.country"
              :rules="[
                validateRules.required(propertyAddress.country, 'Country'),
                validateRules.maxLength(propertyAddress.country, 'Country', 10),
              ]"
              dense
              filled
              label="Country"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col class="d-none" md="4" sm="12">
            <label>Latitude</label>
            <v-text-field
              v-model.trim="propertyAddress.latitude"
              :rules="[
                validateRules.maxLength(
                  propertyAddress.latitude,
                  'Latitude',
                  100
                ),
              ]"
              dense
              filled
              label="Latitude"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col class="d-none" md="4" sm="12">
            <label>Longitude</label>
            <v-text-field
              v-model.trim="propertyAddress.longitude"
              :rules="[
                validateRules.maxLength(
                  propertyAddress.longitude,
                  'Longitude',
                  100
                ),
              ]"
              dense
              filled
              label="Longitude"
              solo
              flat
              color="cyan"
            ></v-text-field>
          </v-col>
          <v-col md="12" sm="12">
            <label class="font-size-16 font-weight-500">Remarks</label>
            <v-textarea
              v-model.trim="propertyAddress.remarks"
              :rules="[
                validateRules.maxLength(
                  propertyAddress.remarks,
                  'Remarks',
                  512
                ),
              ]"
              auto-grow
              dense
              filled
              label="Remarks"
              solo
              flat
              row-height="30"
              color="cyan"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="$emit('close', true)"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        class="mx-2 custom-bold-button white--text"
        v-on:click="appendLocalPerson"
        color="cyan"
        :disabled="!formValid"
      >
        <span v-if="doUpdate">Update</span>

        <span v-else>Add</span>
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";

import { v4 as uuidv4 } from "uuid";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
//import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { SET_CC_PERSONS } from "@/core/services/store/common.module";
import { PUT, QUERY } from "@/core/services/store/request.module";
import { CustomerEventBus } from "@/core/lib/customer.lib";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      NewpropertyAddress: [],
      contactPersons: [],
      emails: [],
      trackGPS: false,
      autocompleteLoading: false,
      searchAddress: null,
      isFocused: false,
      autocompleteList: new Array(),
      propertyAddress: {
        id: null,
        type: 1,
        is_same: 0,
        is_tenant: 0,
        unit_no: null,
        contact_person: null,
        street_1: null,
        street_2: null,
        country: "Singapore",
        zip_code: null,
        latitude: null,
        longitude: null,
        remarks: null,
      },
      timeoutLimit: 500,
      timeout: null,
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    addressListValue: {
      type: Array,
      default() {
        return [];
      },
    },
    personDialog: {
      type: Boolean,
      default: false,
    },
    isTenant: {
      type: Boolean,
      default: false,
    },
    doSave: {
      type: Boolean,
      default: false,
    },
    doUpdate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    personId: {
      type: Number,
      default: 0,
    },
    personUuid: {
      type: String,
      default: null,
    },
    personAlldata: {
      type: Object,
      default: null,
    },
    disableOne: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initComponent();
      },
    },
    personDialog() {
      this.initComponent();
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      },
    },
    personAlldata: {
      deep: true,
      immediate: true,
      handler(param) {
        // console.log(param,"param");
        if (param) {
          this.propertyAddress = param;
          //  this.contactPersons = param.contact_person;
        } else {
          this.propertyAddress = {
            id: null,
            type: 1,
            is_same: 0,
            is_tenant: 0,
            unit_no: null,
            contact_person: null,
            street_1: null,
            street_2: null,
            country: "Singapore",
            zip_code: null,
            latitude: null,
            longitude: null,
            remarks: null,
          };
        }
      },
    },
  },
  methods: {
    clearAddress() {
      const _this = this;
      _this.searchAddress = null;
      _this.propertyAddress.latitude = null;
      _this.propertyAddress.longitude = null;
      _this.propertyAddress.street_1 = null;
      _this.propertyAddress.street_2 = null;
      //_this.propertyAddress.country = "Singapore";
      // _this.propertyAddress.unit_no = null;
      //  _this.propertyAddress.contact_person = null;
      _this.propertyAddress.zip_code = null;
    },
    selectGoogleAddress(index) {
      const _this = this;
      if (_this.lodash.isEmpty(_this.autocompleteList[index]) === false) {
        let place_id = _this.autocompleteList[index].place_id;
        _this.autocompleteLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "google-api/place-detail",
            data: { "place-id": place_id },
          })
          .then((response) => {
            _this.formatAddress(response.data);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.clearAddress();
            _this.autocompleteList = new Array();
            _this.autocompleteLoading = false;
          });
      }
    },

    getContactPerson() {
      const _this = this;
      _this.contactPersons = [];
      if (_this.customerId) {
        _this.$store
          .dispatch(QUERY, {
            url: `contact-person-list/${_this.customerId}`,
            data: {
              "is-property": 1,
            },
          })
          .then(({ data }) => {
            _this.contactPersons = data;
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },

    getUserCurrentAddress() {
      const _this = this;
      if (_this.trackGPS === true) {
        _this.clearAddress();
        navigator.geolocation.getCurrentPosition(
          (position) => {
            _this.getStreetAddressFrom(
              position.coords.latitude,
              position.coords.longitude
            );
          },
          (error) => {
            _this.logError(error);
          }
        );
      }
    },

    getGoogleAddress() {
      const _this = this;
      try {
        if (_this.searchAddress) {
          _this.trackGPS = false;
          _this.autocompleteLoading = true;
          clearTimeout(_this.timeout);
          _this.autocompleteList = new Array();
          _this.timeout = setTimeout(function () {
            _this.$store
              .dispatch(QUERY, {
                url: "google-api/autocomplete",
                data: { search: _this.searchAddress },
              })
              .then((response) => {
                _this.autocompleteList = response.data;
              })
              .catch((error) => {
                _this.logError(error);
              })
              .finally(() => {
                _this.autocompleteLoading = false;
              });
          }, _this.timeoutLimit);
        }
      } catch (error) {
        _this.logError(error);
      }
    },

    formatAddress(param) {
      const _this = this;
      try {
        _this.autocompleteLoading = true;
        _this
          .formatGPSAddress(param)
          .then((result) => {
            if (_this.lodash.isEmpty(param) === false) {
              _this.searchAddress = null;
              _this.propertyAddress.type = 1;

              _this.propertyAddress.latitude = result.latitude;
              _this.propertyAddress.longitude = result.longitude;
              _this.propertyAddress.street_1 = result.street_1;
              _this.propertyAddress.street_2 = result.street_2;
              // _this.propertyAddress.unit_no = result.unit_no;
              _this.propertyAddress.zip_code = result.zip_code;
              _this.propertyAddress.is_same = result.is_same;
              _this.propertyAddress.is_tenant = result.is_tenant;
              //  _this.propertyAddress.country = result.country;
              // _this.propertyAddress.contact_person = result.contact_person;
              // _this.propertyAddress.remarks = result.remarks;
              _this.$emit("updateMapMarker", {
                lat: result.latitude,
                long: result.longitude,
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.autocompleteLoading = false;
          });
      } catch (error) {
        _this.logError(error);
      }
    },

    getStreetAddressFrom(lat, long) {
      const _this = this;
      try {
        if (lat && long) {
          _this.autocompleteLoading = true;
          _this.$store
            .dispatch(QUERY, {
              url: "google-api/lat-long",
              data: { lat, long },
            })
            .then((response) => {
              _this.$emit("updateMapMarker", { lat, long });
              _this.formatAddress(response.data);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.autocompleteLoading = false;
            });
        }
      } catch (error) {
        _this.logError(error);
      }
    },
    appendDisplayName() {
      if (!this.doUpdate) {
        const result = [];
        const { first_name, last_name } = this.contactPerson;
        if (first_name) {
          result.push(first_name);
        }
        if (last_name) {
          result.push(last_name);
        }
        this.contactPerson.display_name = result.join(" ");
        console.log(this.contactPerson.display_name, "appendDisplayName");
      }
    },
    initComponent() {
      const result = this.lodash.cloneDeep(this.value);
      this.NewpropertyAddress = result;

      // console.log({result, personId: this.personId})

      if (this.doUpdate) {
        this.$nextTick(() => {
          let property_address = this.lodash.find(this.NewpropertyAddress, {
            id: this.personId,
          });

          if (!property_address) {
            property_address = this.lodash.find(this.NewpropertyAddress, {
              uuid: this.personUuid,
            });
          }

          if (property_address) {
            this.$nextTick(() => {
              this.contactPerson = {
                id: property_address.id,
                uuid: property_address.uuid,
                type: property_address.type,
                is_same: property_address.is_same,
                is_tenant: property_address.is_tenant,
                unit_no: property_address.unit_no,
                contact_person: property_address.contact_person,
                street_1: property_address.street_1,
                street_2: property_address.street_2,
                country: property_address.country,
                zip_code: property_address.zip_code,
                latitude: property_address.latitude,
                longitude: property_address.longitude,
                remarks: property_address.remarks,
                contactIndex: property_address.contactIndex,
              };
            });
          }
        });
      } else {
        this.$nextTick(() => {
          let contactIndex = this.contactPersons.length + 1;
          this.propertyAddress = {
            id: null,
            uuid: uuidv4(),
            type: 1,
            is_same: 0,
            is_tenant: 0,
            unit_no: null,
            contact_person: null,
            street_1: null,
            street_2: null,
            country: "Singapore",
            zip_code: null,
            latitude: null,
            longitude: null,
            remarks: null,
            contactIndex: contactIndex,
          };
        });
      }
    },
    appendLocalPerson() {
      const address_list = this.addressListValue;

      const exist_index = this.lodash.findIndex(address_list, {
        uuid: this.propertyAddress.uuid,
      });
      if (exist_index >= 0) {
        address_list.splice(exist_index, 1, this.propertyAddress);
      } else {
        address_list.push(this.propertyAddress);
      }
      console.log(address_list, "address_list");
      CustomerEventBus.$emit("address-list", address_list);
      this.$nextTick(() => {
        this.$emit("close", true);
      });
    },

    async appendContactPerson() {
      if (!this.$refs.personForm.validate()) {
        return false;
      }

      // let propertyAddressId = this.propertyAddress.id;

      let temp_persons = this.lodash.cloneDeep(this.NewpropertyAddress);
      //  console.log(temp_persons,"temp_persons");

      if (this.personUuid) {
        let existIndex = this.lodash.findIndex(temp_persons, {
          uuid: this.personUuid,
        });
        //console.log(existIndex,"existIndex")
        if (existIndex >= 0) {
          temp_persons.splice(existIndex, 1, {
            //menu: false,
            id: this.propertyAddress.id,
            uuid: this.propertyAddress.uuid,
            type: this.propertyAddress.type,
            is_same: this.propertyAddress.is_same,
            is_tenant: this.propertyAddress.is_tenant,

            unit_no: this.propertyAddress.unit_no,
            contact_person: this.propertyAddress.contact_person,
            street_1: this.propertyAddress.street_1,
            street_2: this.propertyAddress.street_2,
            country: this.propertyAddress.country,
            zip_code: this.propertyAddress.zip_code,
            latitude: this.propertyAddress.latitude,
            longitude: this.propertyAddress.longitude,
            remarks: this.propertyAddress.remarks,
            contactIndex: this.propertyAddress.contactIndex,
          });
        }
      } else {
        temp_persons = {
          //menu: false,
          id: this.propertyAddress.id,
          uuid: uuidv4(),
          type: 1,
          is_same: 1,
          is_tenant: 0,
          unit_no: this.propertyAddress.unit_no,
          contact_person: this.propertyAddress.contact_person,
          street_1: this.propertyAddress.street_1,
          street_2: this.propertyAddress.street_2,
          country: this.propertyAddress.country,
          zip_code: this.propertyAddress.zip_code,
          latitude: this.propertyAddress.latitude,
          longitude: this.propertyAddress.longitude,
          remarks: this.propertyAddress.remarks,
          contactIndex: this.propertyAddress.contactIndex,
        };

        CustomerEventBus.$emit("input", temp_persons);
        this.$emit("update:person", this.contactPerson);
        this.$emit("close", true);
        return false;
      }
      if (this.propertyAddress.contactIndex) {
        // console.log(this.contactPerson.contactIndex);
        // this.$emit('doUpdateEvent', this.contactPerson);
        this.doUpdate = false;
      }

      if (!this.propertyAddress.contactIndex) {
        this.NewpropertyAddress.push({
          //menu: false,
          id: this.propertyAddress.id,
          uuid: this.propertyAddress.uuid,
          type: this.propertyAddress.type,
          is_same: this.propertyAddress.is_same,
          is_tenant: this.propertyAddress.is_tenant,

          unit_no: this.propertyAddress.unit_no,
          contact_person: this.propertyAddress.contact_person,
          street_1: this.propertyAddress.street_1,
          street_2: this.propertyAddress.street_2,
          country: this.propertyAddress.country,
          zip_code: this.propertyAddress.zip_code,
          latitude: this.propertyAddress.latitude,
          longitude: this.propertyAddress.longitude,
          remarks: this.propertyAddress.remarks,
          contactIndex: this.propertyAddress.contactIndex,
        });
      } else {
        this.NewpropertyAddress[this.propertyAddress.contactIndex - 1] = {
          //  menu: false,
          id: this.propertyAddress.id,
          uuid: this.propertyAddress.uuid,
          type: this.propertyAddress.type,
          is_same: this.propertyAddress.is_same,
          is_tenant: this.propertyAddress.is_tenant,

          unit_no: this.propertyAddress.unit_no,
          contact_person: this.propertyAddress.contact_person,
          street_1: this.propertyAddress.street_1,
          street_2: this.propertyAddress.street_2,
          country: this.propertyAddress.country,
          zip_code: this.propertyAddress.zip_code,
          latitude: this.propertyAddress.latitude,
          longitude: this.propertyAddress.longitude,
          remarks: this.propertyAddress.remarks,
          contactIndex: this.propertyAddress.contactIndex,
        };
      }

      if (this.contactPerson.contactIndex) {
        this.doUpdate = false;
      }

      if (this.doSave || this.doUpdate) {
        this.contactPerson.tenant = this.lodash.toSafeInteger(this.isTenant);
        try {
          const uuid = uuidv4();

          this.contactPerson.uuid = uuid;

          const { data } = await this.$store.dispatch(PUT, {
            url: `customer/${this.customerId}/contact-person`,
            data: { persons: [this.contactPerson] },
          });

          this.contactPerson = this.lodash.find(data, { uuid });

          this.contactPersons = data;
        } catch (error) {
          this.logError(error);
          return false;
        }
      }

      //this.$emit("input", this.contactPersons);

      this.$emit("update:person", this.contactPerson);

      if (this.isTenant) {
        const tenant = this.lodash.cloneDeep(this.contactPerson);

        this.$emit("update:tenant", tenant);
      }

      this.$store.dispatch(SET_CC_PERSONS, this.contactPersons);

      this.$nextTick(() => {
        this.propertyAddress = {
          uuid: uuidv4(),
          id: null,
          type: 1,
          is_same: 0,
          is_tenant: 0,
          unit_no: null,
          contact_person: null,
          street_1: null,
          street_2: null,
          country: "Singapore",
          zip_code: null,
          latitude: null,
          longitude: null,
          remarks: null,
        };
      });

      this.$emit("close", true);
    },
  },
  components: {
    Dialog,
    //PhoneTextField,
  },
  computed: {
    ...mapGetters(["cc_persons"]),

    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  mounted() {
    this.getContactPerson();

    this.initComponent();

    /* CustomerEventBus.$on("input-name", (param) => {
  console.log(param, "this.contactPersonsthis.contactPersons");
                this.contactPersons = param;
                console.log(this.contactPersons, "this.contactPersonsthis.contactPersons");
                  
    });  */

    /* CustomerEventBus.$on("update:property-contact-person", (argument) => {
        console.log(argument, "argument")
      this.contactPersons = argument
    }); */
  },
};
</script>
